<!-- Localized -->
<template>
  <div class="fixed inset-0 overflow-hidden transition-all duration-100 ease-in-out"  style="z-index:9999" :class="{ 'block': isOpen, 'hidden': !isOpen, }">
    <div class="absolute inset-0 bg-gray-500 opacity-50" @click="close"></div>
    <div class="absolute inset-y-0 right-0 max-w-full flex drawer_height">
      <div class="relative w-screen ">
        <div class="h-full bg-white overflow-x-auto max-w-full drawerDropdown">
        <button @click="closedDrawer" class="crossBtn"><svg data-v-6d93e47d="" width="27" height="26" fill="none" xmlns="http://www.w3.org/2000/svg" class=""><path data-v-6d93e47d="" d="M7.375 6.184l12.728 12.728M7.375 19.273L20.103 6.546" stroke="#000000c9" stroke-width="4" stroke-linecap="round"></path></svg></button>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import crossIcon from "@shared/assets/icons/cross.svg";
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closedDrawer() {
      this.close();
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    handleClickOutside(e) {
      if (this.isOpen && !this.$el.contains(e.target)) {
        this.close();
      }
    },
  },
  mounted() {

  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.block {
  display: block;
}
.drawerDropdown{
  bottom: 0px !important;
  position: fixed;
  width: 100%;
  height: 270px;
}
.crossBtn {
  width: 26px;
  height: 20px;
  position: absolute;
  top: 11px;
  z-index: 9;
  right: 7%;
}
</style>
